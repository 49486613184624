.signupform {
    background-color: rgba(0, 0, 0, 0.0);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 20px;
    width: 400px;
    font-family: var(--font-family);
}

.signupform-wrapper {
    padding: 3rem;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
}

.signupform-form {
    display:flex;
    flex-direction: column;
    gap: 1rem;
}

.signupform-input-wrapper input {
    height: 40px;
    width: 250px;
    border-radius: 3px;
    border: none;
    outline: none;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 16px;
    align-self: center;
}

.signupform-input-wrapper input:nth-child(n+2){
    margin-top: 1rem;
}


.signupform-input-wrapper p {
    text-align: left;
    padding-left: 15px;
    padding-top: 5px;
    color: rgb(255, 125, 125);
}

.signupform-error-text{
    text-align: center;
    color: rgb(252, 68, 68);
    font-size: 12px;
}

.signupform-submit-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.signupform-submit-wrapper p{
    font-size: 13.5px;
    align-self: center;
}


.signupform-input-wrapper{
    display: flex;
    flex-direction: column;
}

.signupform-submit-wrapper button {
    height: 40px;
    width: 282px;
    color: white;
    background-color: #15A9FC;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400px;
    align-self: center;
}
.signupform-submit-wrapper button:disabled {
    cursor: not-allowed;
    background-color: rgb(48, 48, 48);
}

.signupform-submit-wrapper button:hover:not(:disabled) {
    background-color: #44bbff;
}

.signupform-header{
    display:flex;
    align-items: center;
    justify-content: center;
}

.signupform-form input:focus {
    border: 2px #15A9FC;
}