#editReviewFormDeleteButton{
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 110px;
    height: 25px;
    background-color: #ff4f4f;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
}

#editReviewFormDeleteButton:hover{
    cursor: pointer;
    background-color: #ff7575;
}