.popfilms__movieheader{
    display: flex;
    flex-wrap: nowrap;
    gap: 3rem;
    position: relative;
}

.moviePNG{
    width: 300px;
    height: 450px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}

.popfilms__movieinfo {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.popfilms__movieinfo p{
    color: white;
    font-family: var(--font-family);
}

.popfilms__movieinfo h1 {
    color: white;
    font-family: var(--font-family);
    font-weight: 700;
}

.popfilms__movieinfo-topcontainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.review{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.review > .webrating{
    display: flex;
    flex-direction: row;
    gap: .25rem;
    align-items: center;
}

.webrating img{
    width: auto;
    height: 10px;
}

.review p {
    font-size: 13.5px;
    font-weight: 600;
}

.dtr
{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.dtr p, #genre
{
    font-size: 13.5px;
    color: #c4c4c4;
}


#summary {
    max-width: 700px;
    line-height: 2;
    font-size: 15px;
    font-weight: 400;
}

.dwscontainer {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.dwschild {
    display: flex;
    flex-direction: row;
}

.dwscontainer > .dwschild > p:first-child {
    color: #c4c4c4;
    min-width: 8rem;
    font-size: 13.5px;
}

.dwscontainer > .dwschild > p:last-child {
    line-height: 1.5;
    font-size: 13.5px;
}

.dwscontainer > .dwschild > :last-child {
    max-width: 600px;
}

.dtrgrcontainer {
    display: flex;
    flex-direction: column;
    gap: .25rem;
}
