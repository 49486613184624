.popfilms-searchresults{
    position: absolute;
    top: 40px;
    left: 150px;
    width: 300px;
    height: auto;
    background-color: #1D1E1F;
    outline: none;
    border: none;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    padding-top: .5rem;
    padding-bottom: .5rem;
    box-shadow: 0 4px 10px rgba(0,0,0,.3);
}

.popfilms-searchresults-result{
    display: flex;
    flex-direction: row;
    font-size: 14px;
    gap: .75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.popfilms-searchresults-result-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.searchResultImage{
    width: 45px;
    height: 63px;
    border-radius: .25rem;
}

.popfilms-searchresults-result-info{
    color: rgba(255, 255, 255, 0.5);
}

.popfilms-searchresults-result:hover{
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.popfilms-loader{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 2px;
    position: relative;
    border-top: 1.1em solid #f2f3f4;
    border-right: 1.1em solid #f2f3f4;
    border-bottom: 1.1em solid #f2f3f4;
    border-left: 1em solid #15A9FC;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    animation: loading 1.1s infinite linear;
    margin: 0 auto 10px;

}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}