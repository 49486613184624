.moviepage {
    max-width: 1920px;
    margin: auto;
    position: relative;
}
.moviepage-content {
    padding-top: 46px;
}

.moviepage_body {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 3rem;
}

.background-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    opacity: .2;
    mask-image: url('../../Assets/mask2.svg');
    -webkit-mask-image: url('../../Assets/mask2.svg');
}

.blur {
    width: 100%;
    height: 100%;
    position: fixed;
}


